/*============Preloading Screen==================*/
.loader-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  z-index: 1000111;
  background-color: $theme-color;
  opacity: 1;
  transition:  all 500ms linear 0s;
}
.loader-content.loader-off,
body.loaded .loader-content {
  opacity: 0;
  visibility: hidden;
  transition:  all 500ms linear 0s;
}
/* The loader container */

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    text-align: center;

    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: $white;
  border-radius: 100%;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

/* imgEffect-hinge-up
   ----------------------------- */

[class^='imgEffect-'] {
  position: relative;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  @include transform(translateZ(0));
  > img {
    vertical-align: top;
    max-width: 100%;
  }
    figcaption {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

}

[class^='imgEffect-'], [class^='imgEffect-']:before,
[class^='imgEffect-']:after,
[class^='imgEffect-'] *, [class^='imgEffect-'] *:before,
[class^='imgEffect-'] *:after {
  box-sizing: border-box;
  @include transition (all 0.35s ease);
}

[class^='imgEffect-hinge-']{
    @include perspective(50em);
    figcaption {
        opacity: 0;
        z-index: 1;
    }
    &:hover {
        img {
            opacity: 0;
        }
        figcaption {
            opacity: 1;
            -webkit-transition-delay: 0.21s;
            -moz-transition-delay: 0.21s;
            transition-delay: 0.21s;
        }
    }
}
 /* imgEffect-hinge-up
   ----------------------------- */
.imgEffect-hinge-up {
    img {
        -webkit-transform-origin: 50% 0%;
        -moz-transform-origin: 50% 0%;
        -ms-transform-origin: 50% 0%;
        -o-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
    }
    figcaption {
        @include transform(rotateX(90deg));
        -webkit-transform-origin: 50% 100%;
        -moz-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
        -o-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
    }
    &:hover {
        > img {
            @include transform(rotateX(-90deg));
        }
        figcaption {
            @include transform(rotateX(0));
        }
    }
}



// zoom
.zoom-2{
    overflow: hidden;
    img{
        position: relative;
        width: percentage(1);
        @include transition(all .2s ease-out);
        @include transform-origin(center,center);
    }
    &:hover{
        img{
            @include scale(1.2);
        }
    }
}

// filter grayscale
.filter-grayscale {
    @include transition(all 0.6s ease-out 0s);
    &:hover{
        @include filter(grayscale(100%));
    }
}

// filter brightness
.filter-brightness {
    @include transition(all 0.4s ease-out 0s);
    &:hover{
        @include filter(brightness(0.4));
    }
}

// filter blur
.filter-blur{
    @include transition(all 0.6s ease-out 0s);
    &:hover{
        @include filter(blur(5px));
    }
}

.tab-pane{
    @include animation-name(fadeInUp);
    @include animation-duration(0.7s);
    @include animation-fill-mode(both);
    height: 0;
    overflow: hidden;
    display: block !important;
    &.active {
        height: auto;
    }
}

.close{ 
    .fa{
        @include transition(all 1s ease-in-out);
    }
    &:hover{
        .fa{
            @include rotate(360deg);
        }
    }
}
.image-overlay-1{
    &:after,&:before{
        content:"";
        display: block;
        position: absolute;
        z-index: 100;
        background: rgba($black,.7);
        @include square(percentage(1));
        @include opacity(0);
        @include transition(all 0.3s ease 0s);
    }
    &:after{
        top: -100%;
    }
    &:before{
        bottom: -100%;
    }
    &:hover{
        &:after{
            top: -50%;
            @include opacity(1);
        }
        &:before{
            bottom: -50%;
            @include opacity(1);
        }
    }
}
// Plus Button Effects
.image-plus-1{
    position: relative;
    &::before{
        overflow: hidden;
        position: absolute;
        top: 0;
        content: "";
        z-index: 100;
        @include square(percentage(1));
        
        @include opacity(0);
        @include transition(all 0.3s ease 0s);
        @include transform(scale(0.5) rotateY(180deg));
        background: url('#{$image-theme-path}plus.png') no-repeat scroll center center / 60px 60px rgba($black,.6);
    }
    &:hover{
        &::before{
            visibility: visible;
            @include opacity(.6);
            @include transform(scale(1) rotateY(0deg));
        }
    }
}

.image-plus-2{
    position: relative;
    &::before{
        content: "";
        z-index: 199;
        top: 0;
        position: absolute;
        background: url('#{$image-theme-path}plus.png') no-repeat scroll center center / 60px 60px rgba($black,.8);
        @include square(percentage(1));
        
        @include transition(all 0.3s ease 0s);
        @include opacity(0);
        @include background-size(10px 10px, 100% 100%);
        @include background-origin(padding-box, padding-box);
    }
    &:hover{
        &::before{
            opacity: .6;
            visibility: visible;
            @include background-size(60px 60px, 100% 100%);
        }
    }
}

.image-plus-3{
    position: relative;
    &::before{
        content: "";
        top: 0;
        overflow: hidden;
        position: absolute;
        z-index: 100;
        @include transform(scale(0.5) rotateX(180deg));
        @include square(percentage(1));
        
        @include opacity(0);
        @include transition(all 0.3s ease 0s);
        background: rgba($black,.8);
    }
    &:hover{
        &::before{
            visibility: visible;
            @include opacity(.6);
            @include transform(scale(1) rotateX(0deg));
        }
    }
}

// Icon Effect 1
.icon-effect-1{
    position: relative;
    &:before{
        content: "";
        display: block;
        @include scale(0.5);
        @include border-radius(percentage(.5));
        @include transition(transform 0.5s cubic-bezier(0.19,1,0.22,1),background-color 0.2s cubic-bezier(0.19,1,0.22,1));
        @include vertical-center(100%,100%);
        background-color: transparent;
    }
    &:hover{
        &:before{
            @include scale(1);
            @include transition(transform 0.5s cubic-bezier(0.19,1,0.22,1),background-color 0.2s cubic-bezier(0.19,1,0.22,1));
        }
    }
} 

//Effect portfolio
.ih-item.square{
    position: relative;
    .info{
        backface-visibility: hidden;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        background: #333333;
        color: $white;
        > *{
            color: $white;
        }
        h3{
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            padding: 10px;
            background: $overlay_dark_dark;
            margin: 30px 0 0 0;
            font-size: $font-size-base + 1;
        }
        p{
            font-style: italic;
            position: relative;
            color: #bbb;
            padding: 20px 20px 20px;
            text-align: center;
            font-size: $font-size-base;
        }
    }
}
.ih-item.square.effect2 {
    overflow: hidden;
    &.colored {
        .info {
            background: #333333;
            h3 {
                background: #111111;
            }
        }
    }
    .img {
        opacity: 1;
        @include transition(  all .5s ease-in-out );
        @include transform( rotate(0deg) scale(1) );
    }

    .info {
        background: #333333;
        visibility: hidden;
        @include transition(  all .35s .3s ease-in-out );
        h3 {
            @include transform( translateY(-200px) );
            @include transition(  all .35s .6s ease-in-out );
        }

        p {
            @include transform( translateY(-200px) );
            @include transition(  all .35s .5s linear );
        }
    }

    a:hover {
        .img {
            @include transform( rotate(720deg) scale(0) );
            opacity: 0;
        }

        .info {
            visibility: visible;

            h3,
            p {
                @include transform( translateY(0) );
            }
        }
    }
}

.ih-item.square{
    &.effect3.bottom_to_top {
        overflow: hidden;
        .info {
            top: auto;
            @include opacity(0);
            @include transform( translateY(100%) );
            @include transition(all 0.35s);
        }
        .img{
            @include transition(all 0.35s);
        }
        a:hover {
            .img {
                @include transform( translateY(-50px) );
                
            }
            .info {
                 @include opacity(1);
                @include transform( translateY(0) );
            }
        }
    }
}

.ih-item.square.effect5 {
    .img {
        @include transition(  all .35s ease-in-out );
        @include transform( scale(1) );
    }

    .info {
        background: $overlay_dark_fallback;
        opacity: 0;
        @include transition( all .35s ease-in-out );
    }

    a:hover {
        .img {
            @include transform( scale(0) );
            @include transition-delay(0, 0);
        }

        .info {
            visibility: visible;
            opacity: 1;
            @include transform( scale(1) rotate(0deg) );
            @include transition-delay(0.3s, 0.3s);
        }
    }
}

.ih-item.square.effect5.left_to_right {
    .info {
        @include transform( scale(0) rotate(-180deg) );
    }
}

.ih-item.square.effect6 {
    overflow: hidden;
    
    .img {
        @include transition(  all .35s ease-in-out );
        @include transform( scale(1) );
    }

    .info {
        background: $overlay_dark_fallback;
        background: $overlay_dark;
        visibility: hidden;
        opacity: 0;
        @include transition(  all .35s ease-in-out );
        h3 {
            @include transition(  all .35s ease-in-out );
        }
        p {
            @include transition(  all .35s .1s linear );
        }
    }

    a:hover {
        .img {
            @include transform( scale(1.2) );
        }

        .info {
            visibility: visible;
            opacity: 1;
        }
    }
}

.ih-item.square.effect6.bottom_to_top {
    .info {
        h3 {
            @include transform( translateY(100%) );
        }

        p {
            @include transform( translateY(100%) );
        }
    }

    a:hover {
        .info {
            h3,
            p {
                @include transform( translateY(0) );
            }            
        }
    }
}

.ih-item.square.effect7 {
    overflow: hidden;
    .img {
        @include transition(  all .35s ease-in-out );
        @include transform( scale(1) );
    }

    .info {
        background: $overlay_dark_fallback;
        background: $overlay_dark;
        visibility: hidden;
        opacity: 0;
        @include transition(  all .35s ease-in-out );
        h3 {
            @include transform( scale(4) );
            @include transition(  all .35s .1s ease-in-out );
        }
        p {
            @include transform( scale(5) );
            @include transition(  all .35s .3s linear );
        }
    }

    a:hover {
        .img {
            @include transform( scale(1.2) );
        }

        .info {
            visibility: visible;
            opacity: 1;

            h3,
            p {
                @include transform( scale(1) );
            }
        }
    }
}

.ih-item.square.effect8 {
    overflow: hidden;
    .img {
        opacity: 1;
        @include transition(  all .35s ease-in-out );
    }

    .info {
        background: $overlay_dark_fallback;
        visibility: hidden;
        opacity: 0;
        @include transition(  all .35s ease-in-out );

        h3 {
            @include transition(  all .35s .1s ease-in-out );
        }

        p {
            @include transition(  all .35s .15s linear );
        }
    }

    a:hover {
        .img {
            opacity: 0;
        }

        .info {
            visibility: visible;
            opacity: 1;

            h3,
            p {
            }
        }
    }
}

// -------------------------
.ih-item.square.effect8.scale_up {
    .img {
        @include transform( scale(1) );
    }

    .info {
        @include transform( scale(0) );

        h3,
        p {
            @include transform( scale(0) );
        }
    }

    a:hover {
        .img {
            @include transform( scale(5) );
        }

        .info {
            @include transform( scale(1) );

            h3,
            p {
                @include transform( scale(1) );
            }
        }
    }
}

.ih-item.square.effect10 {
    overflow: hidden;
    .img {
        @include transition(  all .35s ease-in-out );
    }

    .info {
        background: $overlay_dark_fallback;
        visibility: hidden;
        opacity: 0;
        @include transition(  all .35s ease-in-out );
    }

    a:hover {
        .info {
            visibility: visible;
            opacity: 1;
        }
    }
}

// -------------------------
.ih-item.square.effect10.left_to_right {
    .img {
        @include transform( translateX(0) );
    }

    .info {
        @include transform( translateX(-100%) );
    }

    a:hover {
        .img {
            @include transform( translateX(100%) );
        }

        .info {
            @include transform( translateX(0) );
        }
    }
}

.ih-item.square.effect12 {
    overflow: hidden;
    .img {
        @include transition( all .35s ease-in );
    }

    .info {
        background: $overlay_dark_fallback;
        background: $overlay_dark;
        visibility: hidden;
        opacity: 0;
        @include transition( all .35s ease-in );
        h3 {
            @include transition( all .35s ease-in );
        }
        p {
            @include transition( all .35s ease-in );
        }
    }

    a:hover {
        .info {
            visibility: visible;
            opacity: 1;
            @include transition-delay( 0.2s, 0.2s );

            h3 {
                @include transition-delay( 0.3s, 0.3s );
            }

            p {
                @include transition-delay( 0.25s, 0.25s );
            }
        }
    }
}
 
// -------------------------
.ih-item.square.effect12.left_to_right {
    .info {
        @include transform( translate(-460px, -100px) rotate(-180deg) );

        h3 {
            @include transform( translateY(-100px) );
        }

        p {
            @include transform( translateX(-300px) rotate(-90deg) );
        }
    }

    a:hover {
        .info {
            @include transform( translate(0px, 0px) );

            h3 {
                @include transform( translateY(0px) );
            }

            p {
                @include transform( translateX(0px) rotate(0deg) );
            }            
        }
    }
}
 
.ih-item.square.effect14 {
    overflow: hidden;
    .img {
        opacity: 1;
        @include transition(  all .35s ease-in-out );
    }

    .info {
        background: $overlay_dark_fallback;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        @include transform( scale(.5) );
        @include transition( all .35s ease .2s);
    }

    a:hover {
        .img {
            opacity: 0;
            pointer-events: none;
            @include transform( scale(.5) );
        }

        .info {
            visibility: visible;
            opacity: 1;
            @include transform( scale(1) );
        }
    }
}

// -------------------------
.ih-item.square.effect14.left_to_right {
    .img {
        @include transform( translateX(0) rotate(0) );
    }

    a:hover {
        .img {
            @include transform( translateX(100%) rotate(180deg) );
        }
    }
}

.ih-item.square.effect15 {
    overflow: hidden;
    @include perspective(900px);
    .img {
        opacity: 1;
        @include transition(  all .4s ease-in-out );
    }

    .info {
        background: $overlay_dark_fallback;
        opacity: 0;
        @include transition(  all .35s ease-in-out .3s);
    }

    a:hover {
        .img {
            opacity: 0;
            visibility: hidden;
        }

        .info {
            visibility: visible;
            opacity: 1;
        }
    }
}

// -------------------------
.ih-item.square.effect15.left_to_right {
    .img {
        @include transform( rotateY(0) );
        @include transform-origin( 100%, 50% );
    }

    .info {
        @include transform( rotateY(90deg) );
        @include transform-origin( 0%, 50% );
    }

    a:hover {
        .img {
            @include transform( rotateY(-90deg) );
        }

        .info {
            @include transform( rotateY(0) );
        }
    }
}


/*============@group Banners hover effect ==================*/
.banners-effect-1 .banners > div {
    img { @include transition(all 0.2s ease-in);
        &:hover{opacity: 0.8;}
    }
}

.banners-effect-2 .banners > div a{display: block;position: relative;overflow: hidden;
    &:hover{
        &:before,&:after{left: 0;opacity: 1;}
    }   
    &:before,&:after{
        background-color: rgba(255, 255, 255, 0.4);
        display: block;
        width: 100%;
        height: 100%;
        left: -100%;
        opacity: 0;
        filter: alpha(opacity=0);
        position: absolute;
        top: 0;
        @include transition(all 0.3s ease-in);
        content: "";
        z-index: 1;
    }
    
}

.banners-effect-3 .banners > div a {display: block;position: relative;overflow: hidden;
    &:hover{
        &:before,&:after{border: 0 solid rgba(0, 0, 0, 0.7);opacity: 0;filter: alpha(opacity=0);}
    }   
    &:before,&:after{
        border: 50px solid transparent;
        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        border-bottom-left-radius: 50px;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        top: 0;
        content: "";
        opacity: 1;
        filter: alpha(opacity=100);
        width: 100px;
        height: 100px;
        @include transform(scale(7));
        @include transition(all 0.4s ease-in-out);
        visibility: visible;
        z-index: 1;
    }
}

.banners-effect-4 .banners > div a {display: block;position: relative;overflow: hidden;
    &:hover{
        &:before,&:after{
            opacity: 1;
            @include transform(rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1));
        }
    }   
    &:before,&:after{
         border-bottom: 50px solid rgba(0, 0, 0, 0.2);
        border-top: 50px solid rgba(0, 0, 0, 0.2);
        content: "";
        height: 100%;
        left: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        position: absolute;
        top: 0;
        transform-origin: 50% 50% 0;
        width: 100%;
        @include transform(rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1));
        @include transition(opacity 0.4s ease 0s, transform 0.35s ease 0s);
        visibility: visible;
        z-index: 1;
    }
}

.banners-effect-5 .banners > div a {display: block;position: relative;overflow: hidden;
    &:before{
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        border: 70px solid rgba(255,255,255, 0);
        top: 0;
        left: 0;
        transition: all 0.5s ease-in-out;
    }
    &:after{
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        opacity: 0.5;
        border: 30px solid #fff;
        top: 0;
        left: 0;
        transform: scale(0);
        -moz-transform: scale(0);
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transition: all 0.5s ease-in-out;
    }

    &:hover{
        &:before{
            border: 0 solid rgba(255,255,255, 0.7);
        }
        &:after{
            transform: scale(0.8);
            -moz-transform: scale(0.8);
            -webkit-transform: scale(0.8);
            -ms-transform: scale(0.8);
            opacity: 0;
            transition-delay: 0.1s;
        }
    }
}


.banners-effect-6 .banners > div a {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background: #000;
    vertical-align: top;
}

    
.banners-effect-6 .banners > div a img {
    backface-visibility: hidden;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 1s ease 0s, transform 1s ease 0s;
    -o-transition: opacity 1s ease 0s, transform 1s ease 0s;
    transition: opacity 1s ease 0s, transform 1s ease 0s;
}


.banners-effect-6 .banners > div a:hover img {
    opacity: 0.8;
    filter: alpha(opacity=80);
    transform: scale3d(1.1, 1.1, 1);
}


.banners-effect-7 .banners > div a {
    display: block;
    position: relative;
    overflow: hidden;
}

     
.banners-effect-7 .banners > div a:before {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: 0;
    top: 0;
    left: 50%;
    content: "";
    transition: all 0.3s ease-in-out 0s;
}
     
          
.banners-effect-7 .banners > div a:hover:before {
   width: 100%;
   left: 0;
   top: 0;
   height: 100%;
}
          

.banners-effect-8 .banners > div a {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background: #000;
    vertical-align: top;
}
     
.banners-effect-8 .banners > div a:before,
.banners-effect-8 .banners > div a:after {
  bottom: 20px;
  content: "";
  left: 20px;
  opacity: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  -webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
  -o-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
  transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
  z-index: 1;
}

.banners-effect-8 .banners > div a:before {
   border-bottom: 1px solid #ffffff;
   border-top: 1px solid #ffffff;
   -webkit-transform: scale(0, 1);
   -ms-transform: scale(0, 1);
   -o-transform: scale(0, 1);
   transform: scale(0, 1);
}

.banners-effect-8 .banners > div a:after {
   border-left: 1px solid #ffffff;
   border-right: 1px solid #ffffff;
   -webkit-transform: scale(1, 0);
   -ms-transform: scale(1, 0);
   -o-transform: scale(1, 0);
   transform: scale(1, 0);
}
.banners-effect-8 .banners > div img {
   opacity: 1;
   filter: alpha(opacity=100);
   -webkit-transition: opacity 0.35s ease 0s;
   -o-transition: opacity 0.35s ease 0s;
   transition: opacity 0.35s ease 0s;
}
.banners-effect-8 .banners > div a:hover:before,
.banners-effect-8 .banners > div a:hover:after {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.banners-effect-8 .banners > div a:hover img {
    opacity: 0.5;
    filter: alpha(opacity=50);
}


.banners-effect-9 .banners > div a {
     display: block;
     position: relative;
     z-index: 10;
}
     

.banners-effect-9 .banners > div a:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: #000;
    content: '';
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
    box-shadow: 0 3px 30px rgba(0,0,0,0.2);
    opacity: 0;
}

.banners-effect-9 .banners > div a:hover:before {
   opacity: 1;
}

.banners-effect-9 .banners > div a img {
    opacity: 1;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: perspective(1000px) translate3d(0,0,0);
    transform: perspective(1000px) translate3d(0,0,0);
}

.banners-effect-9 .banners > div a:hover img {
   -webkit-transform: perspective(1000px) translate3d(0,0,21px);
   transform: perspective(1000px) translate3d(0,0,21px);
}
          
.banners-effect-10 .banners > div a {
     display: block;
     position: relative;
     overflow: hidden;
}
     
.banners-effect-10 .banners > div a:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 75%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 75%);
    content: '';
    opacity: 0;
    -webkit-transform: translate3d(0,50%,0);
    transform: translate3d(0,50%,0);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

.banners-effect-10 .banners > div a:hover:before {
   opacity: 1;
   -webkit-transform: translate3d(0,0,0);
   transform: translate3d(0,0,0);
}


.banners-effect-11 .banners > div a {display: block;position: relative;overflow: hidden;
    &:hover{
        &:before,&:after{
            width:100%;height: 100%;
        }
    }
    
    &:before,&:after{
        background-color: rgba(0, 0, 0, 0.15);
        content: "";
        height: 0;
        left: 0;
        margin: auto;
        position: absolute;
        width: 0;
        @include transition(all 0.3s ease-out 0s);
    }
    &:after{left: auto;right: 0; bottom: 0;}
}

.banners-effect-12 .banners > div {
    img { @include transition(all 0.3s ease-in);
        &:hover{@include filter(grayscale(100%));}
    }
    
}
.sk-fading-circle {
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    position:fixed;
    left:50%;
    top:50%;
    z-index:10000;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

    
.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); 
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); 
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; 
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; 
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; 
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; 
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; 
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; 
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; 
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}