/*--------------------------------------------------------------------------
	Tour
--------------------------------------------------------------------------*/
.box-list-product{
	.item-tour{
		margin-bottom: 30px;
	}
}
.top-list-product-subpage{
	margin-top: 15px;
}
.wrp-title{
	position: relative;
	padding-bottom:10px;
	margin-bottom: 30px;
	&:after{
		content: '';
		background: #0b95ff;
		height: 4px;
		width:146px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin:auto;
	}
	.title-tour{
		text-transform: uppercase;
		color: #011b68;
		font-size: 36px;
		font-weight: bold;
		margin-bottom:3px;
	}
	.slogan-tour{
		font-size: 16px;
		color: #252525;
		text-transform: uppercase;
		margin-bottom:0;
	}
}
.item-tour{
	.inner-img{
		position: relative;
		padding-top:66%;
		img{
			position: absolute;
			top:0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			width:100%;
			object-fit: cover;
		}
	}
}
.inner-info{
	border: 1px solid #d3d3d3;
	.title-detail-tour{
		padding: 13px 17px 2px 17px;
		span{
			display: block;
		}
		a,span{
			font-size: 16px;
			color: #122b74;
			font-weight:bold;
		}
		
	}
	.des-tour{
		padding: 0px 17px 1px 17px;
		color: #2a2929;
		line-height: 22px;
		min-height: 45px;
		a{
			color:#005bb6;
			font-style: italic;
			text-decoration: underline;
		}
	}
	.box-price-cart{
		p{margin-bottom: 0;}
		display: flex;
	    justify-content: space-between;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -ms-flex-align: center;
	    align-items: center;
	    border-top: 1px solid #e5e5e5; 
	    border-bottom: 1px solid #e5e5e5;
	    color:#2a2929;
	    .price{
	    	padding-left: 17px;
	    }
	    .info-router{
	    	padding-top: 5px;
	    	padding-bottom:4px;
	    	padding-left: 15px;
	    	padding-right: 15px;
	    	border-left: 1px solid #e5e5e5;
	    	p{
	    		margin-bottom: 0;
	    	}
	    	.name-tour{
	    		padding-left: 25px;
	    		background: url(/uploads/plugin/custom_img/2019-10-31/1572464375-4030-custom.png) no-repeat left center;
	    	}
	    	.depart{
	    		padding-left: 25px;
	    		background: url(/uploads/plugin/custom_img/2019-10-31/1572464517-8920-custom.png) no-repeat left center;
	    	}
	    }
	}
	.box-reviews{
		padding-top: 17px;
		padding-bottom: 21px;
		padding-right: 20px;
		padding-left: 15px;
		display: flex;
	    justify-content: space-between;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -ms-flex-align: center;
	    align-items: center;
	    p{margin-bottom: 0;}
		.views{

			span{
				color:#029e2a;
				font-size: 16px;
				font-weight:bold;
			}
		}
		.star i{
			color:#fed102;
		}
		.btn-detail-tour{
			height: 37px;
			width:127px;
			line-height: 37px;
			text-align: center;
			color:#fff;
			font-weight:bold;
			background: #122b74;
			@include border-radius(3px);
			-webkit-box-shadow: 2px 6px 3px -2px rgba(0,0,0,0.2);
			-moz-box-shadow: 2px 6px 3px -2px rgba(0,0,0,0.2);
			box-shadow: 2px 6px 3px -2px rgba(0,0,0,0.2);
		}
	}
}
.readmore a{
	margin-top: 34px;
	display: inline-block;
	width:270px;
	height: 46px;
	line-height: 46px;
	font-weight:bold;
	font-size: $font-size-large;
	background: #ff6600;
	color:$white;
	@include border-radius(23px);
	&:hover{
		background: #03c24f;
	}
	i{
		font-size: $font-size-large;
		padding-left: 11px;
	}
}
/*--------------------------------------------------------------------------
	Footer
--------------------------------------------------------------------------*/
#footer{
	position: relative;
	&::after{
		content:'';
		background: url(/uploads/plugin/custom_img/2019-10-30/1572444203-18816-custom.png) no-repeat center;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		height: 120px;
		z-index: -1;
	}
}
.title-footer .menu-footer-vertical{
	.title-menu-ft{
		text-transform: uppercase;
		font-size: 15px;
		color:#212121;
		border-bottom: 0;
		margin-bottom: 16px;
	}
	ul li{
		color: #212121;
		line-height: 18px;
		margin-bottom: 18px;
	}
}
.mapHTML{
	padding-top: 7px;
}
.list-fan-page{
	li{
		margin-right: 4px;
		display: inline-block;
		margin-bottom: 0 !important;
		a i{
			height: 31px;
			width: 31px;
			display: inline-block;
			line-height: 31px;
			text-align: center;
			color:$white;
			@include border-radius(50%); 
			&.fa-facebook{
				background:#6081c4;
			}
			&.fa-instagram{
				background:#864c2e;
			}
			&.fa-twitter{
				background:#43bdf0;
			}
			&.fa-youtube-play{
				background:#f25a4c;
			}
		}
	}
}
.hotlineHTML{
	padding-top: 10px;
	.inner-hotline-img{
		float: left;
	}
	.inner-hotline-content{
		margin-left: 52px;
		h3{
			color:#212121;
			font-size: 19px;
			font-weight:bold;
			margin-bottom:0;
		}
	}
}
.certificationHTML{padding-top: 8px}
.coppyrightHTML{
	margin-bottom: 12px;
	p{
		margin-bottom:0;
		font-size: 12px;
		line-height: 16px;
	}
}
//menu
.menu-footer-section{
	.menu-footer-left,
	.menu-footer-right{
		display: inline-block;
	}
	.menu-footer-left{margin-right: 40px;}
	ul{
		li{
			margin-bottom: 7px !important;
			a{
				color:#212121;
			}
			
		}
	}
	.menu-footer-section-2{
		margin-left: 20px;
		padding-top: 17px;
		position: relative;
		&::before{
			content: '';
			background: url(/uploads/plugin/custom_img/2019-11-08/1573151275-12192-custom.jpg) no-repeat top left;
			height: 1px;
			width: 152px;
			position: absolute;
			top:0;
		}
		li {
			a{
				position: relative;
				&::before{
					content: '';
					width: 7px;
					height: 7px;
					background: #ff6600;
					@include border-radius(50%);
					position: absolute;
					top:5px;
					left: -20px;
				}				
			}
		}
	}

}
/*--------------------------------------------------------------------------
	testimonial
--------------------------------------------------------------------------*/
.testimonial{
	margin-top: 80px;
    margin-bottom: 125px;
	.owl-theme.owl-carousel .owl-nav button{
		background-color: #fff !important;
	    color: #595959 !important;
	    padding: 8px 15px !important;
	    border-radius: 50%;
	    top: calc(50% - 22px);
	}
}
	
.wrp-testimonial{
	background-color: rgba(0, 11, 45, 0.88);
    max-width: 585px;
    padding-top: 40px;
    padding-left: 35px;
    padding-right: 60px;
    padding-bottom: 50px;
	.info-testimonial{
		margin-bottom: 15px;
		display: flex;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -ms-flex-align: center;
	    align-items: center;
	    h4{
	    	margin-bottom: 0;
	    	color:$white;
	    	font-size: 30px;
	    	font-weight:bold;
	    	margin-left: 30px;
	    }
		.avatar{
			height: 70px;
			width: 70px;
			object-fit: contain;
			float: left;
			@include border-radius(50%);
			border: 3px solid $white;
		}
	}
	.content-testimonial{
		color: #fff;
    	line-height: 28px;
		span {
			img{
				width: 45px;
			    height: 45px;
			    display: inline-block;
			    object-fit: contain;
			    vertical-align: bottom;
			    margin-right: 13px;
			}
		}
	}
}
/*--------------------------------------------------------------------------
	Videos
--------------------------------------------------------------------------*/
.title-section-1{
	position: relative;
	text-transform: uppercase;
	color:#011b68;
	font-weight:bold;
	font-size: 24px;
	img{
		padding-right: 15px;
	}
	&::after{
		content: '';
	    background: #0b95ff;
	    height: 4px;
	    width: 146px;
	    position: absolute;
	    bottom: 0;
	    left: 55px;
	    margin: auto;
	}
}
.box-gallery{
	.wrp-title{
		&::after{
			content: none;
		}
	}
	.list-item{
		overflow: hidden;
	}
	.gallery-first{
		.image-wrapper {
			position: relative;
    		padding-top: 60%;
			.img-gallery{
				position: absolute;
				top:0;
				left: 0;
				bottom: 0;
				right: 0;
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
		.image-title{
			position: absolute;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    color: #fff;
		    font-size: 18px;
		    font-weight: bold;
		    background: rgba(0, 11, 45, 0.67);
		    padding: 17px 25px;
		    text-transform: uppercase;
		}
	}
	.gallery-list-item{
		.nh-video-item{
			display: block;
		    margin-bottom: 15px;
		    padding-bottom: 15px;
		    border-bottom: 1px solid #e0e0e0;
		    &:last-child{
		    	margin-bottom: 0px;
				padding-bottom: 0px;
				border-bottom: 0;
		    }
		}

		.gallery-inner-img{
			margin-right: 15px;
			float: left;
			img{
				width: 170px;
				height: 95px;
			}
		}
		.gallery-inner-content{
			h4{
				font-size: 14px;
				color:#212121;
				font-weight: bold;
			}
			p{
				color:#212121;
			}
		}
	}
}
/*--------------------------------------------------------------------------
	News
--------------------------------------------------------------------------*/
.wrap-list.news-section{margin-top: 30px;}
.news-section{
	.news-list{
		max-height: 371px;
    	overflow-y: auto;
    	&::-webkit-scrollbar{
    		width: 10px;
    	}
    	&::-webkit-scrollbar-track{
    		background: #f1f1f1; 
    	}
    	&::-webkit-scrollbar-thumb{
			background: #0e77f6; 
    	}
    	&::-webkit-scrollbar-thumb:hover{
    		background: #555; 
    	}
	}
	.news-first{
		position: relative;
		padding-top: 63.5%;
		.img-blogs{
			img{
				position: absolute;
				top:0;
				bottom: 0;
				left:0;
				right: 0;
				height:100%;
				width: 100%;
				object-fit: cover;
			}
		}
		.info{
			position: absolute;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    font-weight: bold;
		    background: rgba(0, 11, 45, 0.67);
		    padding: 17px 25px;
		    text-transform: uppercase;
		    .title-blogs-item{
		    	a {
			    	color: #fff;
			    	font-size: 18px;
			    }
		    } 
		    .more-blogs{
		    	margin-bottom: 0;
		    	span{
		    		color: #999;
		    		font-size: 12px;
		    	}
		    }
		}
	}
	.style-view-small{
		margin-bottom:30px;
		&:last-child{
			margin-bottom:0;
		}
		.inner-img-news{
			width: 255px;
			height: 170px;
			float: left;
			img{
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
		.inner-info-news{
			margin-left: 275px;
			.title-product a{
				color:#333333;
				font-weight:bold;
			}
			.more-blogs{
				border-bottom:1px solid #ddd;
				margin-bottom:5px;
				padding-bottom: 5px;
				span{
					color:#999;
				}

			}
			
		}
	}
}

//style-view-carousel
.title-section-2{
	font-size: 14px;
	font-weight:bold;
	color:#fff;
	background-color:#011b68;
	padding-top: 14px;
	padding-bottom: 13px;
	padding-left: 18px;
	position: relative;
	margin-bottom: 0;
	&::after{
		content:'';
		width: 4px;
		height: 27px;
		background-color: #f2e90d;
		position: absolute;
		top: 0;
		bottom:0;
		left:0;
		margin: auto;
	}

}
.block-section{
	.wrp-title{
		margin-bottom: 0;
		padding-bottom: 0;
		&::after{
			content: none;
		}
	}
}
.style-view-carousel{
	&:last-child{
		.wrp-view-carousel{
			border: none;
		}
	}
	.more-blogs{
		margin-bottom: 0;
		float:left;
		span{
			background-color:#c12c2e;
			color:#fff;
			padding-left: 4px;
			padding-right: 4px;
			padding-bottom: 1px;
			vertical-align: text-top;
			margin-right: 12px;
		}
	}
	.title-product{
		margin-bottom: 0;
		a{
			font-size: 14px;
			color: #111;
		}
	} 
}
.wrp-view-carousel{
	border-bottom: 1px dashed #ddd;
	margin-bottom: 5px;
	padding-bottom: 15px;

}
.wrp-content-news{
	padding-left:15px;
	padding-right:15px;
	border:1px solid #e0e0e1;
	border-top: 0;
	padding-top: 30px;
}
/*--------------------------------------------------------------------------
	HTML
--------------------------------------------------------------------------*/
.paymentHTML{margin-top: 15px;}
//why
.why-banner {
	background:#000b2d;
	text-align: center;
	img{
		margin-top: 58px;
		margin-bottom:15px;
	}
	h2{
		color:#f1f1f1;
		font-size: 26px;
		margin-bottom: 0;
		padding-bottom: 140px;
		line-height: 39px;
		span{
			font-weight: bold;
			display: block;
			color:#5d81ee;
		}
	}
}
.why-content {
	margin-left: 40px;
	h3{
		color:$white;
		font-size: 20px;
		font-weight: bold;
		height: 46px;
		line-height: 46px;
		width:46px;
		margin-bottom: 13px;
		@include border-radius(50%);
		background:#ff6600;
		text-align: center;

	}
	h4{
		padding-bottom: 12px;
		font-size: 16px;
		position: relative;
		margin-bottom: 10px;
		&::after{
			content: '';
			height: 1px;
			width: 140px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			border-bottom: 1px dashed #212121;
		}
	}
}
.why-grid-1{margin-top: 55px;padding-bottom: 5px;}
//----------------
.inforHTML{
	margin-bottom: 35px;
	padding: 25px 20px 30px;
	border: 1px solid #ebebeb;
	.name-company {
		font-size: 18px;
		color:#011b68;
		font-weight:bold;
		line-height: 24px;
		margin-bottom:18px;
	}
	.address-info {
		margin-bottom: 30px;
		p{
			color: #252525;
			line-height: 24px;
			margin-bottom: 0;
		}
	}
	.title-hotline-support{
		font-size: 14px;
		color:#011b68;
		font-weight:bold;
		

	}
	.list-hotline{
		padding:  10px 0;
		list-style: none;
		background: #ebf4fd;
		width: 270px;
		margin:auto;
		margin-bottom: 0;
		@include border-radius(50px);
		li {
			a{
				color:#ea0000;
				display: block;
				font-weight: bold;
				span{
					color:#3e3e3e;
				}
			}
		}
	}
}
// image Gallery
.title-section-3 {
	margin-bottom: 0;
	background-color: #122b74;
	color:#fff;
	font-size: 14px;
	text-transform: uppercase;
	text-align: center;
	padding: 12px 0;
	@include border-radius(5px);
	-webkit-box-shadow: 2px 6px 3px -2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 6px 3px -2px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 6px 3px -2px rgba(0, 0, 0, 0.2);
}
.box-gallery-img {
	.wrp-title{
		padding-bottom: 0;
	}
	.image-wrapper{
		position: relative;
		margin-bottom: 30px;
		margin-left: 35px;
		margin-right: 35px;
		.image-title{
			position:absolute;
			bottom: 0;
			left: 0;
			right:0;
			text-align: center;
			color:#fff;
			text-transform: uppercase;
			background-color:rgba(0, 115, 233, 0.78);
			padding: 9px 0;
		}
	}
}