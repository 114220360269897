//Body
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  text-align: left; // 3
  margin:0;
}
.bg-gray{
	background: $body-bg;
}
.bg-grey{
	background: #f1f1f1;
}
.bg-black{
	background-color: #000b2d;
}
.bg-idea {
    background: url(/uploads/plugin/custom_img/2019-11-01/1572625221-9736-custom.png) center center no-repeat;
}
.pt-20{padding-top: 20px;}
.pt-25{padding-top: 25px}
.pt-78{padding-top: 78px}
.pt-30{padding-top: 30px}
.pt-50{padding-top: 50px}
.pb-15{padding-bottom: 15px;}
.pb-50{padding-bottom: 50px;}
.pb-60{padding-bottom: 60px;}
.pb-35{padding-bottom: 35px;}
.pb-40{padding-bottom: 40px;}
.py-30{padding-top: 30px;padding-bottom: 30px}
.mb-30{margin-bottom: 30px;}
.help-block{color:red;margin-top: 5px;display: inline-block;}
.owl-theme.owl-carousel{
	.owl-nav{
		margin-top: 0;
		button{
			font-size: 21px !important;
			background: rgba(127, 127, 127, 0.75) !important;
			color:#fff !important;
			position: absolute;
			top:calc(50% - 42px);
			&.owl-prev{
				left: -30px;
				right:auto;
			}
			&.owl-next{
				right: -30px;
				left:auto;
			}
		}
	}
}
.list-item.view-list{
	flex: 0 0 100%;
    max-width: 100%;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{
	border-color: transparent;
}
.nav-tabs{border-bottom: 0;}
.nav-tabs .nav-link{
	border-radius:0; 
}
.no-gutters-bs > .row{
	margin-right: 0;
    margin-left: 0;
}
.no-gutters-bs > .row > .col,
.no-gutters-bs > .row > [class*="col-"]{
	padding-right: 0;
    padding-left: 0;
}
.captcha-reload{
	width: 30px;
	display: inline-block;
	text-align: center;
	color:red;
}
.captcha-input{
	padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}