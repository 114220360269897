//detail news
.title-blogs-item{
	font-size: 24px;
	color:#011b68;
	line-height: 30px;
}
.detail-post{
	margin-top: 27px;
	img{max-width: 100%;height: auto;}
}
//detail tour
#main_img{
	position: relative;
	padding-top:66.66%;
	.img-main-detail{
		position: absolute;
		top:0;
		bottom:0;
		left:0;
		right: 0;
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
}
.thub-image{
	.item{
		@extend #main_img;
		.img-thumb-product{
			@extend .img-main-detail;
		}
	}
	margin-bottom: 13px;
}
.title-name-product{
	font-size: 24px;
	font-weight:bold;
	color: #011b68;
	line-height: 30px;
	margin-bottom: 20px;
	margin-top: 25px;
}
.view-images-large{margin-bottom: 12px;}
.bottom-detail-product{
	nav{
		background: #ebf4fd;
		.nav-item {
			display: inline-block;
			font-weight: bold;
			width: 25%;
			text-transform: uppercase;
			font-size: 13px;
			padding: 8px 10px;
			img{padding-right: 5px;}
			&.active{
				color:#fff;
			}
		}
	}
	.tab-content{margin-top: 30px;margin-bottom: 50px}
}
.info-tour{
	.title-detail-tour{
		background-color: #011b68;
		color: #fff;
		font-weight: bold;
		font-size:14px;
		padding: 23px 18px;
		margin-bottom: 0;
		line-height: 22px;
	}
	.list-info-tour{
		margin-bottom: 30px;
		padding: 25px;
	    list-style: none;
	    background: #f7f7f7 url(/uploads/plugin/custom_img/2019-11-07/1573066313-2503-custom.png) center center no-repeat;
	    background-size: contain;
	    li{
	    	border-bottom: 1px dashed #7f96d7;
		    padding-bottom: 12px;
		    margin-bottom: 14px;
		    span.inner-infor-tour{
		    	color: #0072b6;
			    font-size: 15px;
			    font-weight: bold;		    }
	    	span{
	    		color:#252525;
	    		font-weight: normal;
	    		width: 145px;
	    		display: inline-block;
	    		vertical-align: middle;
	    	}
	    	&:last-child{
	    		border-bottom: 0;
	    		margin-bottom: 0;
	    		padding-bottom: 0;
	    	}
	    }
	}
}
.wrp-booking{
	background: #0073e9;
	padding: 22px 34px;
	margin-bottom: 15px;
	.btn-booking{
		display: block;
		background:#011b68;
		color:#fff !important;
		font-weight:bold;
		padding: 0;
		height: 40px;
		line-height: 40px;
	}
	.price-detail-page {
		span{
			color:#fff;
			font-size: 24px;
			font-weight:bold;
			&.price-before{
				text-decoration: line-through;
				font-size: 18px;
				color:#ddd;
				font-weight:normal;
			}
		}
	}
	.slogan-booking-detail-price{
		margin-top: 4px;
		color:#aad4ff;
	}
	.date-booking{
		color:#909090;
		background-color:#ffffff;
		line-height: 38px;
		height:38px;
		text-align: left;
		padding: 0 15px;
	}
}