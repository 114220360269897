// Language
.#{$app-prefix}-lang {
	img {
		width: 26px;
		height:20px;
		object-fit: cover;
	}
	ul {
		list-style: none;
		margin:0;
		padding:0;
		display: inline-block;
	}
	a {
		display: inline-block;
	}
	.icon_img img{
		width:26px;
		height: 20px;
		object-fit: contain;
	}
	.item-lang{margin-bottom: 6px;}
}
.banner-home{
	text-align: center;
	img{
		width: 100%;
	}
}
body.home{
	position: relative;
	#header{
		position: absolute;
		top:0;
		left: 0;
		right: 0;
		z-index: 9;
	}
	
}
body:not(.home) .bg-header{
	background: url(/uploads/plugin/custom_img/2019-11-04/1572877524-17973-custom.png) top center no-repeat;
    background-size: 100% 107px;
}
.navbar-header{
	position: absolute;
    top: 0;
    left: 15px;	
}