// Color
// -------------------------
$green           : #57bf6d !default;
$white           : #fff !default;
$black           : #000 !default;
$nocolor         : transparent !default;
$yellow          : #e9c931 !default;
$yellow-dark     : #f2cb2e !default;
$red             : #e42234 !default;
$red-dark        : #ce4f3e !default;
$orange          : #fbaf3f !default;
$blue            : #33aebd !default;
$dark            : #121B1F !default;
$cyan            : #01a3d4 !default;
$turquoise       : #9c619c !default;
$brown-light	 : #eb5454 !default;
$color-1		 : #cb2d41 !default;
$color-2		 : #b3b3b3 !default;
$color-3	     : #909cad !default;
$color-4		 : #959595 !default;
$color-5		 : #252525 !default;
 

// Align
// -------------------------
$align-center    : center !important;
$align-left      : left !important;
$align-right     : right !important;

//Font
//--------------------------
$font-family-second		: 'Montserrat', sans-serif !default;

//Font slide 
$font-slide-primary		: 'Lato' !default;
$font-slide-second		: 'Herr Von Muellerhoff' !default;

// Text transform
// -------------------------
$text-none       : none !important;
$text-uppercase  : uppercase !important;
$text-capitalize : capitalize !important;
$text-lowercase  : lowercase !important;
$text-inherit    : inherit !important;


// Theme Margin, Padding
// -------------------------
$theme-margin    : 20px !default;
$theme-padding   : 20px !default;