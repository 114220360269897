/*------------------------------------------------------------------
[Table of contents]

1. Utilities
2. Font
3. Base
4. Layout
5. Page
6. Post
7. Slideshow
8. Form
9. Responsive
10. Widget
-------------------------------------------------------------------*/

@import "vars/vars-global";
@import "vars/variables";
@import "vars/template-vars";

/* 1. Utilities */

@import "themes/vendor-prefixes";

@import "mixins/mixins";
@import "mixins/template";


/* 2. Header */
@import "themes/header";
 
/* 3. Base */
@import "themes/base";

/* 4. Layout */
@import "themes/layout";


/* 5. Layout */
@import "themes/pages";

/* 6. Post */
@import "themes/post";


/* 7. Widget */

@import "themes/shortcodes";

@import "themes/garenal";

@import "themes/menu";

@import "themes/utilities";


/* 9. Slideshow */
@import "themes/slider";




@import "themes/effect";

/* 11. Plugin */
@import "themes/plugin";

/* 11. Responsive */
@import "themes/responsive";