@media(max-width: 1025px){
	.navbar-header{position: static;}
}
@media(max-width: 990px) {
	.box-search .search-suggestion{
		width: 100%;
	}
	.news-section .news-first,
	.gallery-first{margin-bottom: 15px;}
	.news-section .style-view-small .inner-info-news{margin-left: 0;}
	.news-section .style-view-small .inner-img-news{width: 100%;height: auto;float: none;margin-bottom: 10px;}
	.news-section .news-list{max-height: none;}
	.title-section-1{font-size: 18px;}
	.wrp-testimonial{max-width: none;}
	.main-email-news{margin-bottom: 10px;}
	.title-footer,
	.coppyrightHTML{margin-top: 15px;}
	.testimonial{margin-top: 0;margin-bottom: 30px;}
	
	.bottom-detail-product nav .nav-item{
		width: calc(100% / 2);
	}
	.box-gallery-img .image-wrapper{margin-right: 0;margin-left:0;}
	.inforHTML .list-hotline{width: 100%;}
	.inforHTML{padding: 15px;}
	.navbar-header .logo img{
		width: 120px;
		height: auto;
		margin-left: 40px;
	}

}

@media(max-width:768px) {
	
	.in-email .inner-button-reg-email{margin-right: 0;margin-top:10px;}
	.main-email-news{margin-bottom: 10px;}
	.in-email .form-email-news{
		float: none;
		width:100%;
	}
	.testimonial{margin: 0;}
	.wrp-testimonial{padding: 40px 35px 50px;}
	.wrp-title .title-tour{
		font-size: 26px;
	}
	.box-search .btn-search{
		background-image: none;
		padding: 0 30px;
	}
	.box-search span input{
		padding-right: 160px;
	}
	.wrp-title{margin-bottom: 15px;}
	.owl-theme.owl-carousel{
		.owl-nav{
			button{
				&.owl-prev{
					left: 0px;
					right:auto;
				}
				&.owl-next{
					right: 0px;
					left:auto;
				}
			}
		}
	}
}
@media(max-width: 480px){
	.bottom-detail-product nav .nav-item{width: 100%;}
	.navbar-header .logo img{
		width: 80px;
	    height: auto;
	    margin-left: 50px;
	}
}