//order
.box-step{
	ul{
		list-style: none;
		padding-left: 0;
		li {
			&.active{
				.num-of-step{
					color: #fff;
    				background-color: #01639d;
				}
			}
			a{
				span{
					color: #01639d;
				    text-transform: uppercase;
				    font-weight: 700;
				    &.num-of-step{
			    	    height: 38px;
					    width: 38px;
					    line-height: 34px;
					    font-size: 20px;
					    border: 2px solid;
					    margin-right: 15px;
					    border-radius: 100%;
					    border-color: #01639d;
					    color: #01639d;
					    font-weight: 400;
					    display: inline-block;
				    }
				}
			}
		}
	}
}
.list-products-order{
	.delete-order{
		position: absolute;
		top: 0;
		right:0;
		font-size: 20px;
	}
	.media-left{
		float: left;
		img{
			height: 80px;
			width: 80px;
			object-fit: contain;
		}
	}
	.media-body{
		margin-left: 10px;
		.media-heading{
			a{
				color:#333;
				display: inline-block;
				padding-right: 18px;
				font-size: 16px;
			}
		}
		
		.order-quantity{
			margin-top: 0.5rem;
			p{
				display: inline-block;
			}
			.input-cart{
				color:#ff0000;
				font-weight: bold;
				font-size: 18px;
				border: 0;
			}
		}
	}

}
.price-well,.price-contact{
	line-height: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #ff0000;
}
.price-before{
	font-size: 14px;
	color:#ddd;
	text-decoration: line-through;
	font-weight: normal;
}
.item-promotion{
	padding-bottom: 15px;
	.title-promotion{padding-bottom: 10px;}
	.input-group-addon{
	    padding: 9px 12px;
	    font-size: 14px;
	    font-weight: 400;
	    line-height: 1;
	    color: #555;
	    text-align: center;
	    background-color: #eee;
	    border: 1px solid #ccc;
	}

}
.box-cart-right{
	border: 1px solid #ddd;
	padding:15px;
}
.item-cart {padding-bottom: 10px;}
.title_style4{
	h3{
		font-size: 16px;
		text-transform: uppercase;
		font-weight: bold;;
		border-bottom:1px solid #ddd;
		padding-bottom: 10px;
	}
}
.box-cart-left{
	.card{
		.nav-tabs-bank{
			.payment_method_item{
				width: 100%;
		    	margin-bottom: 20px;
		    	float: left;
		    	text-align: left;
		    	&:last-child{
		    		margin-bottom: 0;
		    	}
		    	img{
		    		width: auto;
	    			height: 30px;
	    			display: inline-block;
		    	}
		    	a{
		    		background-color: transparent;
		    		border: 1px solid #333;
		    		padding: 20px 15px;
		    		input{
		    			@include opacity(0);
		    		}
		    	}
		    	&.active,&:hover{
		    		a{
		    			border: 1px solid #01639d;
		    		}
		    		
		    	}
			}
		}
		.tab-content{
			padding: 20px;
		}
		.form-control,.chosen-container-single .chosen-single{
			height: 50px;
		    font-size: 16px;
		    border-radius: 3px;
		}
		.chosen-container-single .chosen-single span{
			height: 50px;
    		line-height: 50px;
		}
		.chosen-container-single .chosen-single div{
			height: 50px;
		    font-size: 16px;
		    padding-top: 13px;
		}
		.nav-tabs{
			border-bottom: 1px solid #ddd;
			li{
				width: 100%;
    			text-align: center;
				a{
				    color: #333;
				    font-weight: 600;
				    background: #e1e1e1;
				    margin: 0;
				    padding: 14px 15px;
				    border-radius: 0;
				    display: block;
				    text-transform: uppercase;
				}
			}
		}
	}
	.button-cart{
		margin: 10px 0 30px;
	}
}
#list-method, #list-method-installment{
	width: 100%;
}
.box-payment {
	.box-cart-left .card .nav-tabs{
		border-bottom:0;
	}
	.title_style4 h3{margin-bottom: 15px;}
}
.list-bank{padding-left: 0;}
input[type="password"], input[type="email"], input[type="text"], textarea.form-control {
    border-radius: 0px;
    padding: 8px 10px 8px 12px;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    color: #888;
    border: 1px solid #eaeaea;
    vertical-align: middle;
    height: auto;
    line-height: 27px;
}
.list-cut{
	margin:30px 0;
	position: relative;
	input{
		padding-right: 55px;
	}
	button{
		position: absolute;
		top:0;
		right:0;
		padding: 11px 15px;
	}
}